import favicon from "../components/logo.png";
import { FaFacebook, FaWhatsapp } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className="my-20 py-4">
      <div
        className="bg-gradient-to-t from-red-500 to-transparent"
        style={{ height: "10px" }}
      ></div>
      <p className="text-center text-sm text-red-500">
        Copyright © {new Date().getFullYear()} Murewa Cps. All rights reserved.
      </p>
      <p className="text-center text-xs text-red-500 mt-1">
        Connect with us:{" "}
        <p>
        <FaWhatsapp
        style={{alignSelf: "center", width: "30px", height: "30px", color: "green"}}
        onClick={() => {
          window.open("https://api.whatsapp.com/send?phone=+26377 241 7093&text=Hello%2C%20I%20would%20like%20some%20Assistance.", "_blank");
        }}
       />
       <FaFacebook
        style={{alignSelf: "center", width: "30px", height: "30px", color: "blue"}}
        onClick={() => {
          window.open("https://www.facebook.com/profile.php?id=61550254225166&__cft__[0]=AZVFU5pYhOlxa1thGiQ8gvQ4s1D5bNKpWxOUDsxRBFRhdqOU0ynd_wwh7WUjbQWrCpuBb7K8Rwj4lIBlXh4YZWrZskR7JuJ674nj5h2bXGl8cNzqPWCuwjwuT5fU43MX-jAgf-KzQN8p3C4BVyR9zAi2d2Z8Akc9R4CG1BD6QXw4ZA&__tn__=-UC%2CP-R", "_blank");
        }}
       />
        </p>
       </p>
      
       
      <p className="text-center text-xs text-red-500 mt-1">
        Made by{" "}
        <img
          height={"70"}
          width={"60"}
          src={favicon}
          onClick={() => {
            window.open("https://webmuba.netlify.app/", "_blank");
          }}
          className="inline cursor-pointer"
        />
      </p>
    </footer>
  );
}


