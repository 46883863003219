import React from 'react';
import image1 from "../images/project2.png"
import image2 from "../images/project1.png"
import image3 from "../images/project3.png"
import pig1 from "../images/pig  (3).JPG"
import pig2 from "../images/pig  (1).JPG"
import pig3 from "../images/pig  (2).JPG"
import chicks from "../images/broilers.jpg"
import video from "../videos/videos.mp4"
import geyser from "../images/Solar Project.JPG"
import chicks2 from "../images/fish Pond.jpg"
import rabbits from "../images/rabbitry.jpg"
import cattle from "../images/cattle.jpg"
import { Carousel } from "react-bootstrap";

const Projects = () => {
  return (
    <>
    <div className="jumbotron">
      <h1 className="display-4 text-center"><em><strong>Welcome To The Projects Page</strong></em></h1>
      <blockquote>
        <h1 className="text-center">Projects In Progress</h1>
      </blockquote>

      <hr className="my-4" />
      <p className="text-uppercase"><strong>The Slides of Pictures Below is a Vision of our School to build A safe living environment for Boarder boys of Murewa Central Primary School .Your Contribution would make this project a SUCCESS</strong></p>
      <p className="text-uppercase"><strong>Our Current Donors </strong></p>
      <ul>
        <li>1. Mr. Moffat Makuto and Family</li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
      <p className="lead text-center"><a className="btn btn-primary btn-lg" href="https://api.whatsapp.com/send?phone=+263772417093&text=Hello%2C%20I%20would%20like%20to%20Donate." role="button" target="_blank">How To Donate</a></p>
    </div>
    <h1 className="text-center">Pictures of The Boys Boarding Project </h1>
    <Carousel className="mt-4">
        <Carousel.Item interval={3000}>
          <img
            className="d-block mx-auto"
            src= {image1}
            alt="First slide"
          />
          <Carousel.Caption>
            <h5>Side Perspective</h5>
            <p>&nbsp;</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block mx-auto"
            src= {image2}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h5>Side Perspective</h5>
            <p>&nbsp;</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block mx-auto"
            src={image3}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h5>Birds Eye View</h5>
            <p>&nbsp;</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    <h1 className="text-center">Pictures of The Piggery Project </h1>
    <div id="carouselExampleIndicators2" className="carousel slide" data-ride="carousel" style={{ backgroundColor: 'grey' }}>
       
      <Carousel className="mt-4">
        <Carousel.Item interval={3000}>
          <img
            className="d-block mx-auto"
            src= {pig1}
            alt="First slide"
          />
          <Carousel.Caption>
            <h5>First of our pigs</h5>
            <p>&nbsp;</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block mx-auto"
            src= {pig2}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h5>Front view of the Pigsty</h5>
            <p>&nbsp;</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block mx-auto"
            src={pig3}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h5>Inside the Pigsty</h5>
            <p>&nbsp;</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    <h1 className="text-center"><strong>Completed Projects</strong></h1>
    <div className="card-deck">
      <div className="card">
        <img className="card-img-top" src={chicks} alt="Card image cap" />
        <div className="card-body">
          <h5 className="card-title">Poultry Project</h5>
          <p className="card-text">A poultry project for meat at a primary school involves raising chickens for consumption and provides an opportunity for students to learn about agriculture and animal husbandry.</p>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Maize planting section</h5>
          <div className="card-video" style={{ width: '100%'}}>
            <video className="card-video__content" controls loop  src={video} style={{ width: '100%', height: '100%', objectFit: 'cover' }}>Your Browser does not support videos</video>
          </div>
          <p className="card-text">As we approach the end of the academic year, it does not only show our Lord's favour to guide the end of the year but also gives us the opportunity as a school to prepare our students for the planting season to come.</p>
        </div>
        </div>
      <div className="card">
        <img className="card-img-top" src={geyser} alt="Card image cap" />
        <div className="card-body">
          <h5 className="card-title">Solar Geyser Project</h5>
          <p className="card-text">A solar geyser project implemented at a girls hostel has provided a sustainable and cost-effective solution for their hot water needs. The project has also helped to reduce the hostel's carbon footprint and promote environmental awareness among the residents.</p>
        </div>
      </div>
      <div className="card">
        <img className="card-img-top" src={chicks2} alt="Card image cap" />
        <div className="card-body">
          <h5 className="card-title">Fish Project</h5>
          <p className="card-text">A fish project for meat at a primary school involves raising fish for consumption while also providing an opportunity for students to learn about aquatic life.</p>
        </div>
      </div>
      <div className="card">
        <img className="card-img-top" src={rabbits} alt="Card image cap" />
        <div className="card-body">
          <h5 className="card-title">Rabbit Project</h5>
          <p className="card-text">A rabbit project for meat at a primary school involves raising rabbits for consumption while also providing an opportunity for students to learn about animal husbandry.</p>
        </div>
      </div>
      <div className="card">
        <img className="card-img-top" src={cattle} alt="Card image cap" />
        <div className="card-body">
          <h5 className="card-title">Cattle Project</h5>
          <p className="card-text">A cattle project for meat at a primary school involves raising cattle for consumption while also providing an opportunity for students to learn about animal husbandry and agriculture.</p>
        </div>
      </div>
    </div>
    </div>
    </>
  );
}
  
export default Projects;
