import React from 'react';
import image3 from "../images/mrs chingore.jpg"
import image1 from "../images/mr chiomba vp.JPG"
import image2 from "../images/MRS MUZONDO ICU.JPG"

const CardComponent = () => {
  const cardData = [
    {
      imgSrc: image1,
      title: 'Visually Challenged Department Led By Mr Chiomba',
      description:
        'A Braille department at school offers Braille instruction, adaptive technology, and support to visually impaired or blind students to ensure equal access to education.',
    },
    {
      imgSrc: image3,
      title: 'ICU Department Led By Mrs Muzondo',
      description:
        'A mentally challenged department at school provides specialized education, support, and adaptive technology to students with mental challenges to facilitate their learning and integration into society.',
    },
    {
      imgSrc: image2,
      title: 'Sports Led By Mrs Chingore',
      description:
        'A sports department at school provides students with opportunities to participate in sports and physical activities, promoting health, teamwork, and personal growth through competition and recreation.',
    },
  ];

  return (
    <div className="row">
      {cardData.map((card, index) => (
        <div key={index} className="col-md-4">
          <div className="card col-md-12">
            <img className="card-img-top" src={card.imgSrc} alt="Card image cap" />
            <div className="card-body">
              <h5 className="card-title">{card.title}</h5>
              <p className="card-text">{card.description}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardComponent;
