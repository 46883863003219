import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import image1 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL001.png";
import image2 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL002.png";    
import image3 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL003.png";
import image4 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL004.png";
import image5 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL005.png";
import image6 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL006.png";
import image7 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL007.png";
import image8 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL008.png";
import image9 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL009.png";
import image10 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL010.png";
import image11 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL011.png";
import image12 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL012.png";
import image13 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL013.png";
import image14 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL014.png";   
import image15 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL015.png";
import image16 from "../images/infant/MUREWA CPS MODEL INFANT SCHOOL016.png"; 

const images = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
];
const InfantSchool = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const variants = {
    shrink: {
      scale: 0.8,
      transition: { duration: 0.3 },
    },
    enlarge: {
      scale: 1.2,
      transition: { duration: 0.3 },
    },
  };

  return (
    <div className="image-container">
      {images.map((image, index) => (
        <motion.img
          key={index}
          src={image}
          alt={`Image ${index + 1}`}
          style={{ width: '100%' }}
          animate={
            index === scrollPosition % images.length
              ? variants.enlarge
              : variants.shrink
          }
        />
      ))}
    </div>
  );
};

export default InfantSchool;
