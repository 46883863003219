import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { motion } from "framer-motion";

import head from "../images/heAD 1.JPG";
import deputy from "../images/Deputy.JPG";
import secretary from "../images/sECRITARY.JPG";
import tic from "../images/MRS MBENDE TIC.JPG";
import seniorMaster from "../images/Mr mubaiwa.JPG";
import hod from "../images/mR cHIOMBA 1.JPG"
import chiefExaminer from "../images/chinhamora chief examiner.JPG";
import sports from "../images/mrs chingore 333.jpg";
import viceSports from "../images/Mr Makore (1).JPG";
import warden from "../images/mrs maziofa warden.JPG";
import warden2 from "../images/maam maringosi.jpg";
import resource from "../images/Mrs Maranyika.JPG";
import teachers from "../images/teachers.jpg"

const employees = [
    {
      name: "Head",
      job: "Mrs Rarayi",
      picture: head, 
    },
    {
      name: "Deputy",
      job: "Mr Chitemerere",
      picture: deputy, 
    },
    {
      name: "Secretary",
      job: "",
      picture: secretary, 
    },
    {
      name: "TIC",
      job: "Mrs Mbende",
      picture: tic, 
    },
    {
      name: "Senior Master",
      job: "Mr Mubaiwa",
      picture: seniorMaster, 
    },
    {
      name: "HOD Special Education",
      job: "Mr Chiomba",
      picture: hod, 
    },
    {
      name: "Chief Examiner",
      job: "Mr Chinamhora",
      picture: chiefExaminer, 
    },
    {
      name: "Sports Organizer",
      job: "Mrs Chingore",
      picture: sports, 
    },
    {
      name: "Vice Sports Organizer",
      job: "Mr Makore",
      picture: viceSports, 
    },
    {
      name: "Warden",
      job: "Mrs Maziofa",
      picture: warden, 
    },
    {
      name: "Warden",
      job: "Mrs Maringosi",
      picture: warden2, 
    },
    {
      name: "Resource TRC",
      job: "Mrs Maravanyika",
      picture: resource, 
    },
  ]
export default function About (){
  return (
    <>
     
      <motion.div
       initial ={{x: "100vw"}}
       animate={{ x: 0}}
       transition={{ type: "spring" , delay: 0.5}}
      >
    <h2 class="text-center mb-5">Our Mission</h2>
	
      <div className="flex flex-col items-center gap-3 mx-auto max-w-4xl mt-16">
        <h2 className="font-bold text-3xl text-gray-800 text-center">
        TO BE THE LEAD PROVIDER AND FACILITATOR OF INCLUSIVE QUALITY EDUCATION FOR SOCIO- ECONOMIC TRANSFORMATION BY 2026.
        </h2>
      </div>
      <motion.div 
      className="flex justify-center gap-10 mx-auto max-w-4xl mt-12"
       initial={{ scale: 0.1 }} // Initial scale (start out small)
        animate={{ scale: 1 }} // Final scale (get bigger)
        transition={{ delay: 1.5 ,duration: 1.5 }} // Transition duration (1 second)
      >
        <Row className="justify-content-center">
      {employees.map((employee, index) => (
        <Col xs={12} sm={6} md={4} key={index} className="mb-4">
          <Card>
            <Card.Img variant="top" src={employee.picture} />
            <Card.Body>
              <Card.Title>{employee.name}</Card.Title>
              <Card.Text>{employee.job}</Card.Text>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
    <h1 class="text-center mb-5">Our Complete Staff</h1>
    <div class="card col-md-4 col-lg-12"> 
    <img
     class="card-img-top" 
     src={teachers} 
     alt="Card image cap"></img>
    </div>
      </motion.div>
      </motion.div>
      
    </>
  );
};

 