import React from "react";
import {  FaEnvelope, FaMapMarkerAlt, FaBookOpen, FaCalendar, FaUserGraduate, FaAward } from "react-icons/fa";
import murewaMaps from "../images/murewaMaps.png";
import murewaStaff from "../images/head.png";
import murewaCurriculum from "../images/Curriculum.jpg";
import murewaFacebook from "../images/Achievements.jpg";
import murewaWhatsapp from "../images/questions.gif";
import murewaCalendar from "../images/calendar.jpg";

export default function Features() {
  const features = [
    {
      title: "Our Staff",
      description: "They are a talented and dedicated group of professionals who are passionate about helping students succeed.",
      icon: <FaUserGraduate size={40} />,
      link: "/About",
      linkText: "Explore Our Staff",
      image: murewaStaff,
    },
    {
      title: "Achievements",
      description: "\"Have a look has achieved many impressive accomplishments over the years",
      icon: <FaAward size={40} />,
      link: "https://www.facebook.com/profile.php?id=61550254225166&__cft__[0]=AZVFU5pYhOlxa1thGiQ8gvQ4s1D5bNKpWxOUDsxRBFRhdqOU0ynd_wwh7WUjbQWrCpuBb7K8Rwj4lIBlXh4YZWrZskR7JuJ674nj5h2bXGl8cNzqPWCuwjwuT5fU43MX-jAgf-KzQN8p3C4BVyR9zAi2d2Z8Akc9R4CG1BD6QXw4ZA&__tn__=-UC%2CP-R",
      linkText: "Our Achievements",
      image: murewaFacebook,
    },
    {
      title: "Curriculum",
      description: "We offer curriculum that is designed to challenge and inspire our students.Take a look click below",
      icon: <FaBookOpen size={40} />,
      link: "/Curriculum",
      linkText: "What We Teach",
      image: murewaCurriculum,
    },
    {
      title: "Our Location",
      description: "We are located in mashonaland east Murewa at Murewa Mission .Our address is one click away ",
      icon: <FaMapMarkerAlt size={40} />,
      link: "https://maps.app.goo.gl/A2Cq4KNcutzCTmxL9",
      linkText: "Find Us",
      image: murewaMaps,
    },
    {
      title: "You Have Questions",
      description: "For all inquires and questions that involve our institute , you may click the button below to talk to our staff who can help you",
      icon: <FaEnvelope size={40} />,
      link: "https://api.whatsapp.com/send?phone=+26377 241 7093&text=Hello%2C%20I%20would%20like%20some%20Assistance.",
      linkText: "Contact Us",
      image: murewaWhatsapp,
    },
    {
      title: "Dont Know when to visit",
      description: "provides a roadmap for achieving academic excellence and supporting the growth and development of our students.",
      icon: <FaCalendar size={40} />,
      link: "/Calendar",
      linkText: "See Our Calendar",
      image: murewaCalendar,
    },
  ];

  return (
    <div className="container mt-4">
      <h2 className="text-center text-4xl font-bold mb-4" style={{color:"red"}}>
        Explore more about us below
      </h2>

      <div className="row mt-4">
        {features.map((item, index) => (
          <div key={index} className="col-md-4">
            <div className="card mb-4 box">
              <img className="card-img-top" src={item.image} alt={`${item.title} image`} />
              <div className="card-body text-center">
                <h3 className="card-title font-semibold">{item.icon} {item.title}</h3>
                <p className="card-text text-muted" >{item.description}</p>
                {item.link && (
                  <div className="d-flex justify-content-center mt-4">
                    <a href={item.link} className="btn btn-primary" rel="noopener noreferrer">{item.linkText || "Learn More"}</a>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}


