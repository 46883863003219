import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav,Container } from 'react-bootstrap';
import Logo from '../assets/logo.png';
export default function Header() {
  useEffect(() => {
    const navLinks = document.querySelectorAll('.navbar-nav .nav-link');
    navLinks.forEach((link) => {
      link.style.padding = '10px 15px';
      link.style.color = '#fff';
      link.style.fontWeight = 'bold';
      link.style.fontSize = '18px';
    });
  }, []);

  return (
      <> 
       <div style={{ zIndex: 1 }}>
    <div className="d-flex justify-content-center">
    <img src={Logo} alt="Logo" width={"140px"} height={"140px"} style={{alignSelf: "center", marginRight: "10px"}}/>
    <p
      style={{color: "black", fontWeight: 'bold', fontSize: '24px', alignSelf: 'center', marginTop: '10px'}}
    >No Mountain is too High to Climb</p>
  </div>
</div>
      <Navbar bg="primary" variant="dark" expand="lg"style={{ zIndex: 1 }}>
        <Container>
          <Navbar.Brand as={Link} to="/">
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/about">About</Nav.Link>
              <Nav.Link as={Link} to="/Projects">Projects</Nav.Link>
              <Nav.Link as={Link} to="/Curriculum">Curriculum</Nav.Link>
              <Nav.Link as={Link} to="/Calendar">Calendar</Nav.Link>
              <Nav.Link as={Link} to="/InfantSchool">Infant School</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
 </>
      
     
  );
}
