import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';

const SchoolCurriculumTable = () => {
  const [fontSize, setFontSize] = useState(14);

  useEffect(() => {
    const handleResize = () => {
      const newFontSize = window.innerWidth / 100;
      setFontSize(newFontSize);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const data = React.useMemo(
    () => [
      { Subject: 'Grade 1', 'Special Education': 'Reading, Writing, Math, Social Skills', Braille: 'Braille Reading, Braille Writing', Mathematics: 'Numbers and Operations, Geometry, Measurement and Data', English: 'Phonics, Vocabulary, Reading Comprehension, Writing, Spelling', Science: 'Life Sciences, Earth Sciences, Physical Sciences', 'Social Studies': 'History, Geography, Culture, Citizenship', 'Physical Education': 'Physical Fitness, Games, Sportsmanship' },
      { Subject: 'Grade 2', 'Special Education': 'Reading, Writing, Math, Social Skills', Braille: 'Braille Reading, Braille Writing', Mathematics: 'Numbers and Operations, Geometry, Measurement and Data', English: 'Phonics, Vocabulary, Reading Comprehension, Writing, Spelling', Science: 'Life Sciences, Earth Sciences, Physical Sciences', 'Social Studies': 'History, Geography, Culture, Citizenship', 'Physical Education': 'Physical Fitness, Games, Sportsmanship' },
      { Subject: 'Grade 3', 'Special Education': 'Reading, Writing, Math, Social Skills', Braille: 'Braille Reading, Braille Writing', Mathematics: 'Numbers and Operations, Geometry, Measurement and Data', English: 'Grammar, Reading Comprehension, Writing, Spelling', Science: 'Life Sciences, Earth Sciences, Physical Sciences', 'Social Studies': 'History, Geography, Culture, Citizenship', 'Physical Education': 'Physical Fitness, Games, Sportsmanship' },
      { Subject: 'Grade 4', 'Special Education': 'Reading, Writing, Math, Social Skills', Braille: 'Braille Reading, Braille Writing', Mathematics: 'Numbers and Operations, Fractions, Measurement and Data, Geometry', English: 'Grammar, Reading Comprehension, Writing, Spelling', Science: 'Life Sciences, Earth Sciences, Physical Sciences', 'Social Studies': 'History, Geography, Culture, Citizenship', 'Physical Education': 'Physical Fitness, Games, Sportsmanship' },
      { Subject: 'Grade 5', 'Special Education': 'Reading, Writing, Math, Social Skills', Braille: 'Braille Reading, Braille Writing', Mathematics: 'Numbers and Operations, Fractions, Decimals, Measurement and Data, Geometry', English: 'Grammar, Reading Comprehension, Writing, Spelling', Science: 'Life Sciences, Earth Sciences, Physical Sciences', 'Social Studies': 'History, Geography, Culture, Citizenship', 'Physical Education': 'Physical Fitness, Games, Sportsmanship' },
      { Subject: 'Grade 6', 'Special Education': 'Reading, Writing, Math, Social Skills', Braille: 'Braille Reading, Braille Writing', Mathematics: 'Numbers and Operations, Fractions, Decimals, Measurement and Data, Geometry', English: 'Grammar, Reading Comprehension, Writing, Spelling', Science: 'Life Sciences, Earth Sciences, Physical Sciences', 'Social Studies': 'History, Geography, Culture, Citizenship', 'Physical Education': 'Physical Fitness, Games, Sportsmanship' },
      { Subject: 'Grade 7', 'Special Education': 'Reading, Writing, Math, Social Skills', Braille: 'Braille Reading, Braille Writing', Mathematics: 'Numbers and Operations, Fractions, Decimals, Measurement and Data, Geometry', English: 'Grammar, Reading Comprehension, Writing, Spelling', Science: 'Life Sciences, Earth Sciences, Physical Sciences', 'Social Studies': 'History, Geography, Culture, Citizenship', 'Physical Education': 'Physical Fitness, Games, Sportsmanship' },
    ],
    []
  );

  const columns = React.useMemo(
    () => Object.keys(data[0]).map((key) => ({ Header: key, accessor: key })),
    [data]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data });

  return (
    <div>
      <h1 className='text-center' style={{ fontSize: `40px` }}>School Curriculum</h1>
      <table {...getTableProps()} style={{ width: '100%', fontSize: `${fontSize}px` }}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} style={{ fontSize: `${fontSize}px` }}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()} style={{ width: column.totalWidth, fontSize: `${fontSize}px` }}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} style={{ fontSize: `${fontSize}px` }}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} style={{ fontSize: `${fontSize}px` }}>
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()} style={{ width: cell.column.totalWidth, fontSize: `${fontSize}px` }}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default SchoolCurriculumTable;

