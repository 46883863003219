import React from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'react-bootstrap/dist/react-bootstrap.min';
import { Helmet } from 'react-helmet';
import Home from './pages/Home';
import About from './pages/About';
import Errorpage from './pages/Errorpage';
import Header from "./components/Header";
import Footer from './components/Footer';
import  Projects  from './pages/Projects';
import Calendar from './pages/Calendar';
import InfantSchool from './pages/InfantSchool';
import Curriculum from './pages/Curriculum';



function App() {
  return (
    <div>
      <BrowserRouter>
      <Header/>
        <Routes>
          <Route index element={<Home />} />
          <Route path="/index.html" element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Projects" element={<Projects />} />
          <Route path="/Calendar" element= {<Calendar />} />
          <Route path="/InfantSchool" element={<InfantSchool />} />
          <Route path="/Curriculum" element={<Curriculum />} />
          <Route path="*" element={<Errorpage />} />
        </Routes>
        <TitleUpdater />
        <Footer/>
      </BrowserRouter>
    </div>
  );
}

function TitleUpdater() {
  const location = useLocation();

  const getPageTitle = () => {
    const path = location.pathname;
    switch (path) {
      case '/':
        return 'MurewaCps-Home';
      case '/about':
        return 'MurewaCps-About';
      case '/Projects':
        return 'MurewaCps-Projects';
      case '/Calendar':
        return 'MurewaCps-Calendar';
      case '/InfantSchool':
        return 'MurewaCps-InfantSchool';
      case '/Curriculum':
        return 'MurewaCps-Curriculum';
      default:
        return 'MurewaCps';
    }
  };

  return (
    <Helmet>
      <title>{getPageTitle()}</title>
    </Helmet>
  );
}

export default App;