import React from "react";
import { Carousel } from "react-bootstrap";
import teachers from "../images/teachers.jpg";
import Features from "../components/Features"
import Flag from "../images/flag.JPG";
import Herbal from "../images/herbal garden.jpg";

export default function Home() {
  return (
    <>
      <Carousel className="mt-4">
        <Carousel.Item interval={3000}>
          <img
            className="d-block mx-auto"
            src= {teachers}
            alt="First slide"
          />
          <Carousel.Caption>
            <h5>Our Staff</h5>
            <p>&nbsp;</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block mx-auto"
            src= {Flag}
            alt="Second slide"
          />
          <Carousel.Caption>
            <h5>Flag Area</h5>
            <p>&nbsp;</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            className="d-block mx-auto"
            src={Herbal}
            alt="Third slide"
          />
          <Carousel.Caption>
            <h5>Herbal Garden</h5>
            <p>&nbsp;</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
      <div className="container">
        <h1 className="text-center mt-4">Welcome to the Murewa Cps</h1>
        <p className="text-center mt-4">
          TO BE THE LEAD PROVIDER AND FACILITATOR OF INCLUSIVE QUALITY
          EDUCATION FOR SOCIO-ECONOMIC TRANSFORMATION BY 2026.
        </p>
      </div>
      <Features/>
    </>
  );
}