import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

function Calendar() {
    const events = [
        {
          title: "Schools Open",
          start: "2024-01-09",
          end: "2024-01-09",
        },
        {
          title: "Minister's Visit",
          start: "2024-01-17",
          end: "2024-01-17",
        },
        {
          title: "Zonal Sports",
          start: "2024-02-24",
          end: "2024-02-24",
        },
        {
          title: "District Sports",
          start: "2024-03-01",
          end: "2024-03-01",
        },
        {
          title: "Provincial Sports",
          start: "2024-03-07",
          end: "2024-03-08",
        },
        {
          title: "End of Term Exams",
          start: "2024-03-11",
          end: "2024-03-11",
        },
        {
          title: "Schools Close",
          start: "2024-03-27",
          end: "2024-03-27",
        },
        {
          title: "Trip to Victoria Falls",
          start: "2024-04-06",
          end: "2024-04-06",
        },
        {
            title: "Holiday",
            start: "2024-04-07",
            end: "2024-05-06",
          },
        {
          title: "New Term",
          start: "2024-05-07",
          end: "2024-05-07",
        },
        {
          title: "AGM",
          start: "2024-05-25",
          end: "2024-05-25",
        },
        {
          title: "Consultation Day",
          start: "2024-06-28",
          end: "2024-06-28",
        },
        {
          title: "Borders Recruitment",
          start: "2024-07-01",
          end: "2024-07-01",
        },
        {
          title: "District Sports",
          start: "2024-07-05",
          end: "2024-07-05",
        },
        {
          title: "Zonal Sports",
          start: "2024-07-12",
          end: "2024-07-12",
        },
        {
          title: "Provincial Sports",
          start: "2024-07-18",
          end: "2024-07-18",
        },
        {
          title: "End of Term Exams",
          start: "2024-07-11",
          end: "2024-07-11",
        },
        {
          title: "Exams",
          start: "2024-07-30",
          end: "2024-08-02",
        },
        {
          title: "Trip to Headlands",
          start: "2024-08-02",
          end: "2024-08-02",
        },
        {
          title: "Trip to Kariba",
          start: "2024-08-02",
          end: "2024-08-04",
        },
        {
          title: "School Closes",
          start: "2024-08-08",
          end: "2024-08-08",
        },
        {
          title: "Vacation School",
          start: "2024-08-19",
          end: "2024-08-30",
        },
        {
          title: "Term Starts",
          start: "2024-09-10",
          end: "2024-09-10",
        },
        {
          title: "ZIMSEC Exams",
          start: "2024-09-23",
          end: "2024-09-23",
        },
        {
          title: "Zonal Sports",
          start: "2024-10-11",
          end: "2024-10-11",
        },
        {
          title: "District Sports",
          start: "2024-10-18",
          end: "2024-10-18",
        },
        {
          title: "Provincial Sports",
          start: "2024-10-25",
          end: "2024-10-25",
        },
        {
          title: "ECD Recruitment",
          start: "2024-10-11",
          end: "2024-10-11",
        },
        {
          title: "Grade 1 Recruitment",
          start: "2024-10-30",
          end: "2024-11-01",
        },
        {
          title: "End of Term Exams",
          start: "2024-11-02",
          end: "2024-11-02",
        },
        {
          title: "School Closes",
          start: "2024-12-05",
          end: "2024-12-05",
        },
  ];

  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          start: "today prev,next",
          center: "title",
          end: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        height="90vh"
        events={events}
      />
    </div>
  );
}

export default Calendar;