import React from 'react'
import SchoolCurriculumTable from '../components/SchoolCurriculumTable'
import CardComponent from '../components/CardComponent'

export default function Curriculum() {
  return (
    <div>
        <SchoolCurriculumTable />
         <CardComponent />
    </div>
  )
}
